<template>
  <div class="home">
    <v-row>
      <v-col>
        <v-btn color="#A0736D" rounded dark :to="{ name: 'Login' }">Log In</v-btn>
      </v-col>
      <v-spacer />
      <v-col>
        <v-btn color="#84C9C8" dark rounded :to="{ name: 'ParticpantForm' }">Commit</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-img src="/summer-of-joy-blue.svg" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-left">
        <p>This July & August Dedicate your Practice (DYP) is back with a new edition! The Summer of Joy!</p>

        <p>Join your friends at Ra Yoga and LiveMetta Pilates and Yoga as we dedicate our practices, support wonderful local charities, attend some fun pop-up events, and pledge an intention to bring more joy into our summer months.  The past few years have been heavy - let's set an intention for joy to prevail.</p>

        <p>Throughout this challenge, we'll explore the true nature of joy and strategies to nurture this powerful state, with the hope of helping you connect with your true self, find more meaning in your daily activities, and experience a sense of peace and happiness that transcends everyday life.</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-btn color="#84C9C8" block dark x-large rounded :to="{ name: 'ParticpantForm' }">Commit</v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-15 pb-4">
      <v-col cols="11">
        <v-img contain src="/5-steps.svg" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-left">
        <v-card class="steps-box">
          <v-card-text class="px-4">
            <ol class="complete-steps">
              <li>Agree to our DYP Joy Pledge</li>
              <li>Donate and Dedicate your Practice to 1 of 6 participating charities</li>
              <li>Choose your fitness goal</li>
              <li>Select your service commitment items</li>
              <li>Write your 'Joy Commitment'</li>
            </ol>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-btn color="#84C9C8" block dark x-large rounded :to="{ name: 'ParticpantForm' }">Get Started</v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-15">
      <v-col cols="10">
        <v-img src="/2023-charities.svg" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <p>Dedicate your Practice to one of the charities below and select a donation amount.  If you meet your DYP Pledge, Ra Yoga and/or LiveMetta will match your donation up to 100%!</p>
      </v-col>
      <v-col cols="10">
        <v-row v-if="!isCharityFindPending && charities.length > 0">
          <v-col
            class="charity-selector-item"
            style="cursor: pointer;"
            cols="4"
            v-for="charity, i in charities"
            :key="charity.id"
            @click="openCharity(i)">
            <v-avatar color="#ffffff" style=" border: 2px solid #FCE4B0;" class="av-icon" size="80">
              <img style="height: unset; border-radius: unset; padding: 4px;" :src="charity.imageUrl" />
            </v-avatar>
            <h4>{{ charity.name }}</h4>
          </v-col>
        </v-row>

        <v-dialog
          max-width="420"
          v-if="selectedCharity"
          v-model="selectedCharity">
          <v-card class="text-center steps-box" style="color: #187C78;">
            <v-card-title>
              <v-col><h3>{{ selectedCharity.name }}</h3></v-col>
            </v-card-title>
            <v-card-text>
              <p>{{ selectedCharity.text }}</p>
            </v-card-text>
            <v-card-actions>
              <v-col><v-btn rounded large depressed @click="closeCharity">Close</v-btn></v-col>
              <v-col><v-btn color="#84c9c8" :href="selectedCharity.link" target="_blank" large dark rounded depressed @click="closeCharity">View Charity</v-btn></v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8" class="mb-8">
        <v-btn color="#84C9C8" block dark x-large rounded :to="{ name: 'ParticpantForm' }">Get Started</v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-15 mb-6">
      <v-col cols="11">
        <v-img src="/checkout-the-joy.svg" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pb-10">
        <joy-commitment-list-home />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JoyCommitmentListHome from '@/components/JoyCommitmentListHome'

export default {
  name: 'Home',

  components: {
    JoyCommitmentListHome
  },

  data() {
    return {
      selectedCharity: null,
    }
  },

  computed: {
    ...mapGetters({
      charities: 'charity/list',
    }),

    isCharityFindPending() {
      return this.$store.state.charity.isFindPending
    }
  },

  methods: {
    openCharity(i) {
      this.selectedCharity = this.charities[i]
    },

    closeCharity() {
      this.selectedCharity = null
    }
  },

  beforeMount() {
    this.$store.dispatch('charity/find')
  }
}
</script>

<style lang="scss">
.v-sheet.v-card.steps-box {
  border: 2px solid #FCE4B0;
  border-radius: 8px;
  box-shadow: 0px 4px 4px #A0736D;

  ol {
    padding-top: 15px;
    li {
      font-size: 16px;
      color: #37B3B1;
      padding-bottom: 15px;
      font-weight: 700;
    }
  }
}

.joy-commitment-list {
  padding: 10px;
}

.joy-item {
  width: 100%;
  background-color: #FFF9EB;
  border-radius: 8px;
}

.joy-vote {
  background-color: #9C9C9C;
}

.joy-vote.v-btn--disabled {
  background-color: #37B3B1;

  .v-icon {
    color: #ffffff;
  }
}

.vote-count {
  display: block;
  margin-top: 6px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #9C9C9C;
}

.complete-steps {
  li::before {
    position: relative;
    content: "";
    display: block;
    float: left;
    top: -4px;
    left: -16px;
    height: 28px;
    width: 28px;
    padding-top: 3px;
    background-color: #37B3B1;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    border-radius: 100%;
  }

  li:nth-of-type(1)::before {
    content: "1"
  }
  li:nth-of-type(2)::before {
    content: "2"
  }
  li:nth-of-type(3)::before {
    content: "3"
  }
  li:nth-of-type(4)::before {
    content: "4"
  }
  li:nth-of-type(5)::before {
    content: "5"
  }
  li:nth-of-type(6)::before {
    content: "6"
  }

  li::marker {
    display: block;
    position: relative;
    color: #ffffff;
    z-index: 3;
  }
}

.dyp-card {
  background-color: #FFFFFF;
  border: 2px solid #FCE4B0;
  border-radius: 8px;
  margin-bottom: 6px;
}

</style>