<template>
  <v-app id="app">
    <v-main id="main">
      <v-container :class="`dyp-main ${routeName}`">
        <v-col class="px-20">
          <router-view/>
        </v-col>
      </v-container>
      <v-container class="footer">
        <v-row justify="center">
          <v-col cols="8" class="pb-0">
            <v-img src="/summer-of-joy.svg" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="mb-0">brought to you by:</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="4" class="pt-0">
            <v-img src="/livemetta-footer.svg" />
          </v-col>
          <v-col cols="4" class="pt-0">
            <v-img src="/rayoga-footer.svg" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  computed: {
    routeName() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss">
h1,h2,h3,h4,h5,h6 {
  font-family: 'Lilita One', cursive;
  color: #37B3B1;
  letter-spacing: 1px;
}

#app {
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #187C78;
  background-image: url('https://images.unsplash.com/photo-1494459940152-1e911caa8cc5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3087&q=80');
  background-size: cover;

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #95bfbf !important;
  }
}

.dyp-main {
  min-height: calc(100vh - 160px);
  padding-top: 120px;
  padding-left: 30px;
  padding-right: 30px;
  background-image: url('/rainbow-bg.svg');
  background-size: 100%;
  background-color: #FFF9EB;
  box-shadow: 0px 0px 28px rgba(0,0,0,0.4);
}

.dyp-main.Dashboard {
  min-height: calc(100vh - 65px);
  padding-top: 65px;
}

.footer {
  height: 160px;
  padding: 20px;
  background-color: #37B3B1;
  color: #1F6F6D;
}

#app .v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: #FFFFFF;
  border-color: #FCE4B0;
}

@media (min-width: 1000px) {
  #app {
    max-width: calc(600 - 30px);
    margin-left: calc((100vw - 600) / 2);
  }
}
</style>
