import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../components/Dashboard.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/cancel',
    name: 'Cancel',
    component: () => import('../views/Cancel.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/LoginForm.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue'),

    children: [
      {
        path: 'participant',
        name: 'ParticpantForm',
        component: () => import(/* webpackChunkName: "participant" */ '../components/ParticipantForm.vue'),
      },
      {
        path: 'agreement',
        name: 'AgreementForm',
        component: () => import(/* webpackChunkName: "participant" */ '../components/CommitAgreement.vue'),
      },
      {
        path: 'charity',
        name: 'CharitySelector',
        component: () => import(/* webpackChunkName: "charity" */ '../components/CharitySelector.vue'),
      },
      {
        path: 'donation',
        name: 'DonationForm',
        component: () => import(/* webpackChunkName: "charity" */ '../components/DonationForm.vue'),
      },
      {
        path: 'studio',
        name: 'ChooseStudio',
        component: () => import(/* webpackChunkName: "charity" */ '../components/ChooseStudio.vue'),
      },
      {
        path: 'fitness',
        name: 'FitnessGoal',
        component: () => import(/* webpackChunkName: "charity" */ '../components/FitnessGoal.vue'),
      },
      {
        path: 'service-project',
        name: 'ServiceProject',
        component: () => import(/* webpackChunkName: "charity" */ '../components/ServiceProject.vue'),
      },
      {
        path: 'joy-commitment',
        name: 'JoyCommitmentForm',
        component: () => import(/* webpackChunkName: "charity" */ '../components/JoyCommitmentForm.vue'),
      },
      {
        path: 'complete',
        name: 'CompleteRegistration',
        component: () => import(/* webpackChunkName: "charity" */ '../components/CompleteRegistration.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
