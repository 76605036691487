import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

import { FeathersVuex } from 'feathers-vuex';
Vue.use(FeathersVuex);

const req = require.context('@/store/feathers/services', true, /service.js$/);
const servicePlugins = req.keys().map((path) => req(path).default);
import auth from '@/store/feathers/feathers.auth';

export default new Vuex.Store({
  plugins: [
    ...servicePlugins,
    auth,
    createPersistedState({
      paths: ['loginSession', 'participant', 'login', 'firstName', 'lastName', 'phoneNumber', 'email', 'selectedCharityId', 'selectedServiceProject', 'rayogaSelected', 'livemettaSelected', 'donationAmount', 'rayogaCommitmentCount', 'livemettaCommitmentCount']
    }),
  ],
  state: {
    loginSession: {},
    login: {},
    firstName: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    selectedCharityId: null,
    selectedServiceProjectId: null,
    rayogaSelected: false,
    livemettaSelected: false,
    donationAmount: null,
    rayogaCommitmentCount: 0,
    livemettaCommitmentCount: 0,
    priceIds: [
      { slug: 'NAMI10', id: 'price_1NMG6tDfHUNcjJh3hXww7jWu' },
      { slug: 'NAMI20', id: 'price_1NMG6tDfHUNcjJh3I18Bpe59' },
      { slug: 'NAMI30', id: 'price_1NMG6tDfHUNcjJh3ZYkE4y8z' },
      { slug: 'CASA10', id: 'price_1NMG91DfHUNcjJh3DLCGZXjn' },
      { slug: 'CASA20', id: 'price_1NMG91DfHUNcjJh3XfDYyW6m' },
      { slug: 'CASA30', id: 'price_1NMG91DfHUNcjJh3RrLJz5wv' },
      { slug: 'RM10', id: 'price_1NMG9YDfHUNcjJh3kaAD7izL' },
      { slug: 'RM20', id: 'price_1NMG9YDfHUNcjJh32bH4wVFZ' },
      { slug: 'RM30', id: 'price_1NMG9YDfHUNcjJh3n5jzwkWy' },
      { slug: 'PP10', id: 'price_1NMGADDfHUNcjJh3k1Jpad7J' },
      { slug: 'PP20', id: 'price_1NMGADDfHUNcjJh3a9AVH7zt' },
      { slug: 'PP30', id: 'price_1NMGADDfHUNcjJh3ogN0BT2X' },
      { slug: 'LGBTQ10', id: 'price_1NMGAiDfHUNcjJh3rdlhIScU' },
      { slug: 'LGBTQ20', id: 'price_1NMGAiDfHUNcjJh30iqJpz0t' },
      { slug: 'LGBTQ30', id: 'price_1NMGAiDfHUNcjJh33ehwBLD9' },
      { slug: 'FS10', id: 'price_1NMGB7DfHUNcjJh3TXIEdiW4' },
      { slug: 'FS20', id: 'price_1NMGB7DfHUNcjJh3PyIcsSKZ' },
      { slug: 'FS30', id: 'price_1NMGB7DfHUNcjJh3Q0y8melm' },
    ]
  },
  mutations: {
    setLoginSession(state, val) {
      state.loginSession = val
    }
  },
  actions: {
  },
  modules: {
  }
})
